<template>
    <div class="hourPicker">
        <div v-for="j in 25" :key="j" style="display: flex !important; flex-direction: column !important; justify-content: space-around !important; width: 4.17% !important">
            <div v-for="i in 8" :key="i" style="width: auto !important">
                <div v-if="j===1 && i===1" style="height: 14px; width: auto !important">
                </div>
                <div v-if="j===1 && i>1" style="width: auto !important; text-align: center">
                    {{days[Object.keys(days)[i-2]].substring(0,1)}}
                </div>
                <div v-if="j>1 && i==1" style="font-size: 100%; margin-right:1px; text-align:center; width: auto !important">
                    {{j-2}}
                </div>
                <div v-if="(j>1 && i>1)" style="width: 100% !important">
                  <div
                    :style="[isCorner(i-2,j-2) ? cornerStyle (i-2,j-2) : '', 'width: auto !important']"
                    :class="setHourClass(i-2,j-1)"
                    @click="selectHour(i-2,j-2)">
                  </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
  name: 'HoursPicker',
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      days: this.returnDays(),
      initialHours: this.modelValue
    }
  },
  created () {
    this.hours = this.modelValue
  },
  updated () {
    this.hours = this.modelValue
    // console.log('hours in hrs picker updated')
  },
  emits: ['update:modelValue'],
  computed: {
    hours: {
      get () {
        return this.modelValue
      },
      set (newVal) {
        this.initialHours = newVal
      }
    }
  },
  methods: {
    selectHour (day, hour) {
      const dayInLetters = Object.keys(this.days)[day]
      this.hours[dayInLetters][hour] = Math.abs(-1 * (this.hours[dayInLetters][hour] - 1))
      this.$emit('update:modelValue', this.modelValue)
    },
    returnDays () {
      return { mon: this.$t('days.Monday'), tue: this.$t('days.Tuesday'), wed: this.$t('days.Wednesday'), thu: this.$t('days.Thursday'), fri: this.$t('days.Friday'), sat: this.$t('days.Saturday'), sun: this.$t('days.Sunday') }
    },
    isCorner (day, hour) {
      const corner = hour % 23 === 0 && day % 6 === 0
      return corner
    },
    cornerStyle (day, hour) {
      const pixelRadius = '4px'
      let bordersRadiusValues = ['0', '0', '0', '0']
      const sum = day + hour
      let position = sum === 29 ? 3 : Math.abs(Math.floor(sum / 6) - sum % 6)
      const realPositions = [0, 3, 1, 2]
      position = realPositions[position]
      bordersRadiusValues[position] = pixelRadius
      bordersRadiusValues = bordersRadiusValues.join(' ')
      return `border-radius: ${bordersRadiusValues}`
    },
    setHourClass (day, hour) {
      const isSet = this.hours[Object.keys(this.days)[day]][hour - 1]
      return {
        hourAvailable: isSet,
        hourNotAvailable: !isSet
      }
    }
  }
}
</script>

<style scoped>
.hourPicker {
    color: black;
    margin-left: -25px;
    display: flex;
    /*max-width: inherit;*/
    height: 165px !important;
    width: auto !important;
    font-family: inherit !important;
}

.hourAvailable, .hourNotAvailable {
    height: 21px;
    /* width: 36px; */
    border: 1px solid rgba(39, 38, 38, 0.589);
    cursor: pointer;
}
.hourAvailable {
    background-color: #88c6df !important;
    color: #88c6df !important;
}
.hourNotAvailable {
    /*background-color: #e29248 !important;
    color: #e29248 !important;*/
    background-color: #dd250d !important;
}
.hourAvailable:hover {
    background-color: #436f98 !important;
    color: #436f98 !important;
}
.hourNotAvailable:hover {
    /* background-color: #c74509 !important;
    color: #c74509 !important; */
    background-color: #da5c4b !important;
}

::selection {
  background: none; /* WebKit/Blink Browsers */
  /*color: #88c6df;*/
  color: inherit;
}
::-moz-selection {
  background: none; /* Gecko Browsers */
  /*color: #88c6df;*/
  color: inherit;
}
</style>
