<template>
    <SuiModal v-model="dialog">
      <SuiModalContent>
              <SuiModalDescription>
                    <div v-if="!isDeleting" class="align-items-center div-form">
                      <h1 v-if="!isNewArea" style="align-self: flex-start; margin-left: -50px;">{{$t('areas.areaForm.areaTitle')}} {{edit_data.name}}</h1>
                      <h1 v-else style="align-self: flex-start; margin-left: -50px;">{{$t('areas.areaForm.areaCreationTitle')}}</h1>
                        <form
                        novalidate
                        class="ui form fields"
                        :class="{'blue medium double loading':isLoadingSave}"
                        autocomplete="off"
                        >
                          <div style="width: 100%; display: flex; flex-direction: column">
                            <div class="top">
                              <div class="top-left">
                                <div class="name_field">
                                  <label class="upper-left-label" >{{$t('areas.areaForm.name')}}</label>
                                  <div class="ui input">
                                    <input
                                      v-if="!isNewArea"
                                      style="width: 70%;
                                      margin-left: 10px"
                                      type="text"
                                      maxlength="25"
                                      :placeholder="$t('areas.areaForm.namePlaceholder')"
                                      v-model="nameOfArea">
                                      <!-- :disabled="disableEdition ? 'disabled': ''" -->
                                    <input
                                      v-else
                                      style="width: 70%; margin-left: 10px"
                                      type="text"
                                      maxlength="25"
                                      :placeholder="$t('areas.areaForm.namePlaceholder')"
                                      v-model="nameOfArea">
                                  </div>
                                      <!-- :disabled="disableEdition ? 'disabled': ''" -->
                                </div>

                                <div class="type_field">
                                  <label class="upper-left-label">{{$t('areas.areaForm.type')}}</label>
                                  <div style="width: 50%">
                                      <div style="width: 100%">
                                        <Select
                                          style="border-radius: 4px;"
                                          :search="true"
                                          :options="areas_types"
                                          v-model="typeOfArea"
                                        />
                                          <!-- :disabled="disableEdition" -->
                                      </div>
                                  </div>
                                </div>

                                <div class="max_people_field">
                                  <!--  data-tooltip="Máximo de personas por reserva" data-position="top center" data-variation="small" -->
                                  <label class="upper-left-label">{{$t('areas.areaForm.maxReservations')}}</label>
                                  <div class="ui input">
                                    <input
                                      style="width: 80px; margin-left: 10px; padding-right: 1px; padding-left: 30px !important"
                                      type="number"
                                      min="1"
                                      max="99"
                                      :value="maxPeopleOfArea"
                                      id="max_people"
                                      @input="checkNumber($event.target)">
                                      <!-- :disabled="disableEdition ? 'disabled' : ''" -->
                                  </div>
                                </div>
                              </div>

                              <div class="top-right">
                                <div style="display: flex; flex-direction: column; align-items: center;justify-content: center;text-align: center">
                                <div class="ui toggle checkbox toggle">
            <input
              type="checkbox"
              :checked="status === 'ACTIVE'"
              @change="changeStatus(id, $event)"
            >
            <label style="margin-left: -6px"></label>
            </div>
            <small>{{ $t(`status.${status}`) }}</small>
          </div>
                              </div>
                            </div>

                            <div class="middle" style="width: 100%; display: flex; margin-bottom: 7px; margin-top: 10px">
                                <div
                                  class="max_hrs_field"
                                  style="display:flex; width: 25%; align-items: center"
                                  >
                                  <label
                                    class="upper-left-label"
                                    :data-tooltip="$t('reservation.maxHoursPerDay')"
                                    data-position="top center"
                                    data-variation="small"
                                >{{$t('areas.areaForm.maxHoursReservations')}}</label>
                                </div>
                                <div style="width: 75%; max-width:75%; display: flex" id="max_hrs_input_fields">
                                  <div v-for="(day, dayEn, index) in days"
                                    :key="day"
                                    style="display: flex; width: 14.3%; max-width: 14.3% !important">
                                    <!-- :class="{'invalidHours':daysWithInvalidBlockHours[index]}" -->
                                      <div
                                        class="primary maxHourDay"
                                        :id="`day_max_hrs_${index}`"
                                        :data-tooltip="dataForToolTip (index)"
                                        :data-position="positionForTooltip (index)"
                                        :data-variation="VariationForTooltip(index)">
                                        <span>{{day.substring(0,1)}}</span>
                                      </div>
                                      <div class="ui input" style="cursor: default">
                                        <input
                                            style="cursor: default"
                                            v-if="!isNewArea"
                                            class="inputMaxHours"
                                            type="number"
                                            :min="form.max_hrs[index]?1:0"
                                            :max="form.max_hrs[index]"
                                            :id="`max_hrs_${index}`"
                                            @input="checkNumber2($event.target)">
                                          <input
                                            style="cursor: default"
                                            v-else
                                            class="inputMaxHours"
                                            type="number"
                                            :min="maxHoursOfArea[index]?1:0"
                                            :max="maxHoursOfArea[index]>0?maxHoursOfArea[index]:0"
                                            :id="`max_hrs_${index}`"
                                            @input="checkNumber2($event.target)">
                                      </div>
                                  </div>
                                </div>
                            </div>

                            <div class="area_rules_container" style="width: 100%; display: flex; margin-bottom: 7px; margin-top: 4px">
                              <div
                                  class="area_rules_field"
                                  style="display:flex; width: 25%; align-items: center"
                                  >
                                  <label
                                    class="upper-left-label"
                                >{{$t('reservation.rules')}}</label>
                                </div>
                                <div class="ui input" style="width: 75%; max-width:75%; display: flex">
                                  <textarea
                                    @focus="changeStyleOfRulesTxtArea"
                                    @blur="changeStyleOfRulesTxtArea"
                                    :class="[fullTxtAreaRulesDisplayed?'txtAreaRulesFull':'txtAreaRules']"
                                    style="border: 1px solid rgba(34,36,38,.15)"
                                    maxlength="2250"
                                    :placeholder="$t('reservation.rules')" rows="1"
                                    v-model="rulesOfUseOfArea"
                                    />
                                    <!-- :disabled="disableEdition" -->
                                </div>
                            </div>

                            <div style="display: flex; flex-direction: column">
                              <label style="margin-bottom: 10px; margin-top:2px">{{$t('areas.areaForm.serviceHours')}}</label>
                              <HoursPicker v-if="serviceHoursOfArea"
                                style="padding-left:16px"
                                :class="{'disabled':isLoadingSave}"
                                :modelValue="serviceHoursOfArea"
                                @update:modelValue="serviceHoursOfArea"
                              />
                            </div>
                          </div>
                          <div style="height: 29px; padding-bottom: 0; margin-bottom:0; display: flex;justify-content: center; align-items: center">
                            <div v-if="v$.form.name.$error || v$.form.type.$error || v$.form.serviceHours.$error" class="mb-2" >
                              <span v-if="v$.form.name.$error" class="text-danger">{{ v$.form.name.$errors[0].$message}}</span>
                              <span v-else-if="v$.form.type.$error" class="text-danger">{{v$.form.type.$errors[0].$message }}</span>
                              <span v-else-if="v$.form.serviceHours.$error" class="text-danger">{{v$.form.serviceHours.$errors[0].$message }}</span>
                            </div>
                          </div>

                          <div class="btns_delete_save">
                              <Button v-if="!isNewArea"
                                :appearance="classBtnObject"
                                class="delete"
                                :text="$t('areas.buttons.deleteArea')"
                                color="third-color"
                                @clicked="deleteArea"
                              />
                                <!-- :disabled="disableEdition" -->
                              <Button v-if="!isNewArea"
                                :appearance="classBtnObject"
                                class="save"
                                :text="$t('areas.buttons.saveChanges')"
                                color="primary"
                                @clicked="saveChanges"
                              />
                                <!-- :disabled="disableEdition" -->
                              <Button v-else
                                :appearance="classBtnObject"
                                class="d-inline"
                                :text="$t('areas.buttons.createArea')"
                                color="primary"
                                @clicked="createNewArea"
                              />
                                <!-- :disabled="disableEdition" -->
                              <div style="position: absolute; right: 0; display: flex; flex-direction: row; align-items: center">
                                <!-- <label>{{$t('reservation.activeReservationControl')}} ({{getDefaultControlReservationDays()}} {{$t('reservation.days')}})</label><br /> -->
                                <label>{{$t('reservation.reservationControl')}}</label>
                                <div class="ui input">
                                  <input
                                    style="width: 40px; margin: 3px 3px; padding-right: 1px; padding-left: 3px !important; text-align: center;"
                                    type="text"
                                    maxlength="2"
                                    v-model.number="form.lockReservationsUntilDays"
                                    min="0"
                                    max="99"
                                    @copy="preventInput"
                                    @paste="preventInput"
                                    @auxclick="preventInput"
                                    @keypress="onlyNumber"
                                    @input="validateLockReservationsUntilDays"/>
                                </div>
                                <label>{{$t('reservation.days')}}</label>
                                <!-- <div class="ui input">
                                  <input
                                    style="width: 80px; margin-left: 10px; padding-right: 1px; padding-left: 30px !important"
                                    type="number"
                                    min="1"
                                    max="99"
                                    :value="maxPeopleOfArea"
                                    id="max_people"
                                    @input="checkNumber($event.target)">
                                </div> -->
                                <!-- <div
                                  class="ui toggle checkbox text-center"
                                  style="float: right; margin-right: 5px">
                                  <input
                                    type="checkbox"
                                    :checked="form.isLimitDaysRuleActive"
                                    @change="form.isLimitDaysRuleActive = $event.target.checked"
                                  >
                                  <label style="margin-left: -6px"></label>
                                </div> -->
                                  <!-- :disabled="disableEdition ? 'disabled': ''" -->
                              </div>
                          </div>

                        </form>
                    </div>

                    <div v-else class="align-items-center div-form" :class="{'ui active massive primary inline loader':isDeleting}" style="background: transparent !important; margin-top: 200px !important;">
                      <h2 style="margin-top: 100px !important; color: black; text-align: center !important; margin-left: 15px; color: #2c3e50; font-family: inherit; font-size: 24px ">{{$t('areas.loadingDelete')}}</h2>
                    </div>
              </SuiModalDescription>
              <a @click="((!isLoadingSave && !isDeleting)||isDeleted)?close():''" :class="{'disabled':!((!isLoadingSave && !isDeleting)||isDeleted)}">
                  <i style="text-decoration:none !important; cursor: pointer;font-size: 25px; position: sticky; left: 95%; top: 15px"
                      class="times icon general-blue-color"
                  ></i>
              </a>
      </SuiModalContent>
    </SuiModal>
</template>

<script>
import { SuiModal, SuiModalContent, SuiModalDescription } from 'vue-fomantic-ui'
import HoursPicker from '@/views/Residential/HoursPicker'
import Select from '@/components/form/Select'
import Button from '@/components/form/Button'
import { createSocialArea, deleteSocialArea, updateSocialArea, toggleStatusSocialArea } from '@/api/socialArea'
import { mapActions, mapGetters } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators/dist/raw.esm'
import globals from '../../utils/globals'
// import FileUploader from '@/components/form/FileUploader'

export default {
  name: 'AreaModal',
  props: {
    edit_data: {
      type: Object,
      required: false
    },
    areas_types: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      required: false,
      default: true
    },
    residentialId: {
      type: Number,
      required: true
    },
    disableEdition: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    HoursPicker,
    Select,
    Button
    // FileUploader
  },
  computed: {
    classBtnObject () {
      return {
        height: '29px',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        padding: '7px 2.4rem !important'
      }
    },

    ...mapGetters('types', ['getIconByID']),
    ...mapGetters('socialAreas', ['allAreas']),

    isNewArea () {
      return Object.keys(this.edit_data).length < 2
    },
    dialog () {
      return this.show
    },
    id () {
      return this.edit_data.id
    },
    deleting_progress: function () {
      return `level level-${this.deleting_level}`
    },
    nameOfArea: {
      get () {
        return this.form.name
      },
      set (newVal) {
        this.form.name = newVal
      }
    },
    descriptionOfArea: {
      get () {
        return this.form.description
      },
      set (newVal) {
        this.form.description = newVal
      }
    },
    rulesOfUseOfArea: {
      get () {
        return this.form.rulesOfUse
      },
      set (newVal) {
        this.form.rulesOfUse = newVal
      }
    },
    photoURLOfArea: {
      get () {
        return this.form.photoUrl ? this.form.photoUrl : this.edit_data.photoUrl
      },
      set (newVal) {
        this.form.photoUrl = newVal
      }
    },
    maxPeopleOfArea: {
      get () {
        return this.form.max_people
      },
      set (newVal) {
        this.form.max_people = newVal
      }
    },
    status: {
      get () {
        return this.form.status
      },
      set (newVal) {
        this.form.status = newVal
      }
    },
    typeOfArea: {
      get () {
        return { value: this.form.type.id, text: this.form.type.description }
        // return this.form.type.description
        // return this.form.type ? this.form.type : this.edit_data.type
      },
      set (newVal) {
        this.form.type = { id: newVal.value, description: newVal.text }
      }
    },
    serviceHoursOfArea: {
      get () {
        if (!this.isNewArea) {
          return this.form.serviceHours
        }
        return this.form.serviceHours
      },
      set (newVal) {
        this.form.serviceHours = newVal
      }
    },
    maxHoursOfArea: {
      get () {
        return this.form.max_hrs
      },
      set (newVal) {
        this.form.max_hrs = newVal
      }
    },
    currentMaxHoursOfArea: {
      get () {
        return this.form.current_max_hrs
      },
      set (newVal) {
        this.form.current_max_hrs = newVal
      }
    },
    nameOfPhotoOfArea () {
      let nameOfPhoto = this.getNameOfPhoto()
      const maxLength = 30
      if (nameOfPhoto.length > maxLength) {
        const [name, format] = nameOfPhoto.split('.')
        nameOfPhoto = name.substring(0, maxLength - 1 - format.length) + '...'
      }
      return nameOfPhoto
    }
  },
  emits: ['close'],
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      form: {
        name: '',
        description: '',
        rulesOfUse: '',
        photoUrl: '',
        image: null,
        max_people: 1,
        max_hrs: new Array(7).fill(0), // theoretical max hours
        current_max_hrs: new Array(7).fill(0), // real max hour set in form
        serviceHours: {
          mon: new Array(24).fill(0),
          tue: new Array(24).fill(0),
          wed: new Array(24).fill(0),
          thu: new Array(24).fill(0),
          fri: new Array(24).fill(0),
          sat: new Array(24).fill(0),
          sun: new Array(24).fill(0)
        },
        type: '',
        lockReservationsUntilDays: 0,
        status: 'ACTIVE'
      },
      types: [],
      days: this.returnDays(),
      isLoadingSave: false,
      isDeleting: false,
      isDeleted: false,
      isNewType: false,
      deleting_level: 0,
      isNewPic: false,
      nameOfNewPic: '',
      daysWithInvalidBlockHours: new Array(7).fill(0),
      fullTxtAreaRulesDisplayed: false
      // isLimitDaysRuleActive: true
    }
  },
  validations () {
    return {
      form: {
        name: {
          required: helpers.withMessage('Debes ingresar el nombre del espacio', required),
          isUnique: helpers.withMessage(
            'El nombre del espacio ya existe',
            (nameOfSpace) => !this.allAreas.some(
              elem => elem.name.trim().toLowerCase() === nameOfSpace.trim().toLowerCase() && this.edit_data.name !== nameOfSpace
            )
          )
        },
        type: {
          required: helpers.withMessage(this.$t('areas.areaTypes'), (objType) => this.areas_types.some((typeOfArea) => (objType.id) ? (objType.id === typeOfArea.id) : false))
        },
        serviceHours: {
          required: helpers.withMessage(this.$t('areas.atLeast1Day'), (servHours) => Object.keys(this.getHoursBlocks(servHours)).length > 0)
          // mustBe: helpers.withMessage(
          //   this.$t('areas.validTimeBlocks'), (servHours) => !Object.values(this.getHoursBlocks(servHours)).some(blocks => blocks.some(block => block.length === 4))
          // )
        }
      }
    }
  },
  updated () {
    this.clearForm()
    this.isDeleting = false
    this.isDeleted = false
    if (!this.isNewArea) {
      // console.warn(this.edit_data)
      // console.log(this.edit_data)
      this.form.name = this.edit_data.name
      this.form.description = this.edit_data.description
      this.form.rulesOfUse = this.edit_data.rulesOfUse
      this.form.photoUrl = this.edit_data.photoUrl
      this.form.image = this.form.photoUrl
      this.form.type = { id: this.edit_data.socialAreaType.id, description: this.edit_data.socialAreaType.name }
      this.form.max_people = this.edit_data.maxPeople
      this.form.status = this.edit_data.status
      // this.form.isLimitDaysRuleActive = this.edit_data.isLimitDaysRuleActive
      this.form.lockReservationsUntilDays = this.edit_data.lockReservationsUntilDays
      this.setInitialServiceHours()
      this.setInitialMaxHours()
    } else {
      this.form.name = this.edit_data.name ? this.edit_data.name : ''
      if (document.getElementsByClassName('middle')) {
        for (let day = 0; day < 7; day++) {
          const maxHourDayField = document.getElementById(`max_hrs_${day}`)
          if (maxHourDayField) {
            maxHourDayField.min = 0
            maxHourDayField.max = 0
            maxHourDayField.value = 0
          }
        }
      }
    }
  },
  methods: {
    validateLockReservationsUntilDays () {
      if (this.form.lockReservationsUntilDays < 0) this.form.lockReservationsUntilDays = 0
      if (this.form.lockReservationsUntilDays > 99) this.form.lockReservationsUntilDays = 99
    },
    returnDays () {
      return { mon: this.$t('days.Monday'), tue: this.$t('days.Tuesday'), wed: this.$t('days.Wednesday'), thu: this.$t('days.Thursday'), fri: this.$t('days.Friday'), sat: this.$t('days.Saturday'), sun: this.$t('days.Sunday') }
    },
    onlyNumber ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault()
      }
    },
    changeStatus (e) {
      this.setLoading(true)
      toggleStatusSocialArea(this.id).then((data) => { this.loadAreas() })
        .catch((err) => {
          console.log(err)
          this.$swal(this.$t('errors.somethingHappened'), '', 'error')
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    preventInput ($event) {
      $event.preventDefault()
      return null
    },
    getDefaultControlReservationDays () {
      return globals.defaultControlReservationDays
    },
    changeStyleOfRulesTxtArea () {
      this.fullTxtAreaRulesDisplayed = !this.fullTxtAreaRulesDisplayed
    },

    ...mapActions('loading', ['setLoading']),
    ...mapActions('socialAreas', [
      'load_Areas'
    ]),

    // InvalidHoursStyleInput (index) {
    //   return {
    //     invalidHoursInput: this.daysWithInvalidBlockHours[index],
    //     disabledMaxInputHour: this.daysWithInvalidBlockHours[index]
    //   }
    // },

    dataForToolTip (index) {
      return this.daysWithInvalidBlockHours[index] ? `${this.$t('areas.invalidTimeBlocks')} ${Object.values(this.days)[index].toLowerCase()}` : null
    },

    positionForTooltip (index) {
      return this.daysWithInvalidBlockHours[index] ? 'top center' : null
    },

    VariationForTooltip (index) {
      return this.daysWithInvalidBlockHours[index] ? 'small' : null
    },

    deleteNewPic () {
      this.isNewPic = false
      this.nameOfNewPic = ''
      this.form.photoUrl = this.edit_data.photoUrl
    },
    loadAreas () {
      this.loading = true
      this.setLoading(true)
      // const residentialIdFetch = this.residential.isMaster
      //   ? this.residential.residentialId
      //   : this.residential.masterId
      const residentialIdFetch = this.residentialId
      this.load_Areas(residentialIdFetch)
        .then(() => {
          this.loading = false
          this.setLoading(false)
        })
        .catch(() => {
          this.isError = false
          this.loading = false
          this.setLoading(false)
        })
    },

    getNameOfPhoto () {
      if (this.form.photoUrl) {
        if (!this.isNewPic) {
          const indivPaths = this.edit_data.photoUrl.split('/')
          const idxLast = indivPaths.length - 1
          return indivPaths[idxLast]
        } else {
          return this.nameOfNewPic
        }
      }
      return ''
    },

    setInitialServiceHours () {
      // console.log('Incoming service hours ----->', this.edit_data.serviceHours)
      for (const [day, blocks] of Object.entries(this.edit_data.serviceHours)) {
        for (const block of blocks) {
          let [initialHour, finishHour] = block.split('-')
          finishHour = ['00', '0'].includes(finishHour) ? '24' : finishHour
          const initHourNum = parseInt(initialHour)
          const finishHourNum = parseInt(finishHour)
          if (finishHourNum > initHourNum) {
            for (let hour = initHourNum; hour < finishHourNum; hour++) {
              this.form.serviceHours[day][hour] = 1
            }
          } else {
            for (let hour = initHourNum; hour < 24; hour++) {
              this.form.serviceHours[day][hour] = 1
            }
            for (let hour = 0; hour <= finishHourNum; hour++) {
              this.form.serviceHours[day][hour] = 1
            }
          }
        }
      }
    },

    setInitialMaxHours () {
      this.setMaxHoursPerDay()
      if (document.getElementsByClassName('middle')) {
        for (let day = 0; day < 7; day++) {
          const maxHourDayField = document.getElementById(`max_hrs_${day}`)
          if (maxHourDayField) {
            maxHourDayField.min = this.form.max_hrs[day] ? 1 : 0
            maxHourDayField.max = this.form.max_hrs[day]
            // maxHourDayField.value = this.form.max_hrs[day]
            // this.currentMaxHoursOfArea[day] = this.form.max_hrs[day]
            // console.log('initial max hour for ', Object.keys(this.days)[day], ' is ', this.edit_data.maxHoursReservation[Object.keys(this.days)[day]])
            const dayInAbbrev = Object.keys(this.days)[day]
            const maxHourSettedForDay = this.edit_data.maxHoursReservation[dayInAbbrev]
            if (maxHourSettedForDay) {
              maxHourDayField.value = maxHourSettedForDay
              this.form.current_max_hrs[day] = maxHourSettedForDay
            } else {
              maxHourDayField.value = 0
              this.form.current_max_hrs[day] = 0
            }
          }
        }
      }
    },

    clearForm () {
      this.form = {
        name: '',
        description: '',
        photoUrl: '',
        image: null,
        max_people: 1,
        max_hrs: new Array(7).fill(0),
        current_max_hrs: new Array(7).fill(0),
        serviceHours: {
          mon: new Array(24).fill(0),
          tue: new Array(24).fill(0),
          wed: new Array(24).fill(0),
          thu: new Array(24).fill(0),
          fri: new Array(24).fill(0),
          sat: new Array(24).fill(0),
          sun: new Array(24).fill(0)
        },
        type: '',
        status: 'ACTIVE'
        // isLimitDaysRuleActive: true
      }
      this.isNewType = false
      this.deleting_level = 0
      this.isNewPic = false
      this.nameOfNewPic = ''
      this.daysWithInvalidBlockHours = new Array(7).fill(0)
      this.v$.$reset()
    },

    close () {
      this.$emit('close')
      this.clearForm()
    },

    byteSizeToMBSize (fileSizeInB) {
      const sizeInMB = fileSizeInB / 10e5
      return sizeInMB
    },

    getFileType (file) {
      const fileType = file.type.split('/')[1]
      return fileType
    },

    onFileChange (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      files = [...files]

      if (this.byteSizeToMBSize(files[0].size) > 10) {
        this.$swal(this.$t('areas.fileSize'), '', 'error')
      } else if (
        files.some(file => {
          const nameOfFile = file.name
          const fileExtension = nameOfFile.split('.').pop()
          return !(['jpg', 'png', 'jpeg'].includes(this.getFileType(file))) &&
                  !(['jpg', 'png', 'jpeg'].includes(fileExtension))
        })
      ) {
        this.$swal(this.$t('areas.fileFormat'), '', 'error')
      } else {
        this.isNewPic = true
        this.form.image = files[0]
        this.nameOfNewPic = this.form.image.name
        this.createImage(files[0])
      }
    },
    createImage (file) {
      var reader = new FileReader()
      reader.onload = (e) => {
        this.photoURLOfArea = e.target.result
      }
      reader.readAsDataURL(file)
    },

    checkNumber (sender, index = -1) {
      const min = parseInt(sender.min)
      const max = parseInt(sender.max)
      if (sender.value === '') {
        sender.value = min
      } else {
        const num = parseInt(sender.value)
        index = parseInt(index)
        if (num > max) {
          sender.value = max
          if (sender.id === 'max_people') {
            this.form.max_people = max
          }
        } else if (num < min) {
          sender.value = min
        } else if (min <= num <= max) {
          sender.value = num
        }
        if (sender.id === 'max_people' && !(num < min || num > max)) {
          this.form.max_people = num
        }
      }
    },
    checkNumber2 (sender) {
      const min = parseInt(sender.min)
      const max = parseInt(sender.max)
      if (sender.value === '') {
        // sender.value = min
      } else {
        const num = parseInt(sender.value)
        if (num > max) {
          sender.value = max
        } else if (num < min) {
          sender.value = min
        } else if (min <= num <= max) {
          sender.value = num
        }
      }
    },

    // setNewCategory () {
    //   this.form.type = ''
    //   this.isNewType = !this.isNewType
    // },

    getHoursBlocks (hourMatrix) {
      // const hoursBlockPerDay = {}
      const hoursBlockPerDay = {}
      for (const [day, hours] of Object.entries(hourMatrix)) {
        if (hours.some(hour => hour === 1)) {
          hoursBlockPerDay[day] = []
          const availableHours = []
          for (const [idx, flag] of hours.entries()) {
            if (flag) {
              availableHours.push(idx)
            }
          }
          const newBlocks = []
          hoursBlockPerDay[day] = newBlocks
          let elem = availableHours.shift()
          newBlocks.push([])
          let numOfBlock = 0
          newBlocks[numOfBlock].push(elem)
          const thereAreNextHours = (currentHoursArray) => currentHoursArray.length
          // raw hours blocks are built
          while (thereAreNextHours(availableHours)) {
            const nextElem = availableHours.shift()
            const differenceBetweenCurrenHourAndNextHour = Math.abs(nextElem - elem)
            if (!isNaN(differenceBetweenCurrenHourAndNextHour)) {
              if (differenceBetweenCurrenHourAndNextHour > 1) {
                numOfBlock += 1
                newBlocks.push([])
              }
              newBlocks[numOfBlock].push(nextElem)
              elem = nextElem
            }
          }
        }
      }
      // string blocks are built
      const getHourFormatted = (hour) => hour <= 9 ? `0${hour}` : hour
      // console.log('hoursBlockPerDay before format -------->', hoursBlockPerDay)
      for (const [day, rawHoursBlocks] of Object.entries(hoursBlockPerDay)) {
        rawHoursBlocks.forEach((block, idx) => {
          const initHourBlockRaw = block[0]
          const initHourBlock = getHourFormatted(initHourBlockRaw)
          const finitHourBlockRaw = block[block.length - 1] + 1
          const finitHourBlock = finitHourBlockRaw === 24 ? '00' : getHourFormatted(finitHourBlockRaw)
          hoursBlockPerDay[day][idx] = `${initHourBlock}-${finitHourBlock}`
        })
      }
      return hoursBlockPerDay
    },

    setMaxHoursPerDay () {
      // console.log('------------------------------------ setting max hours per day ------------------------------------')
      // console.log('getHoursBlocks func', this.getHoursBlocks(this.form.serviceHours))
      const currentBlocks = []
      for (const [day, blocks] of Object.entries(this.getHoursBlocks(this.form.serviceHours))) {
        const pushableDay = {}
        const dayIndex = Object.keys(this.days).indexOf(day)
        pushableDay[dayIndex] = blocks
        currentBlocks.push(pushableDay)
      }

      const numberDaysThatHaveBlocks = currentBlocks.map(obj => Object.keys(obj)[0])

      currentBlocks.forEach(obj => {
        const [day, blocks] = Object.entries(obj)[0]

        // const existInvalidBlockInDay = blocks.some(block => block.length === 4)

        // if (!existInvalidBlockInDay) {
        const hoursPerBlock = []
        blocks.forEach(block => {
          let [start, end] = block.split('-').map(val => parseInt(val))
          if (end === 0) {
            end = 24
          }
          hoursPerBlock.push(Math.abs(end - start))
        })
        this.form.max_hrs[day] = Math.max(...hoursPerBlock)
        // }
      })

      Array.from(Array(7).keys()).forEach(daynNumber => {
        if (!numberDaysThatHaveBlocks.includes(`${daynNumber}`)) {
          this.form.max_hrs[daynNumber] = 0
        }
      })
    },

    // setMinHoursPerDay () {

    // },

    formatMaxHoursToDays () {
      const currentMxHrs = document.getElementById('max_hrs_input_fields')

      const getDay = (idx) => Object.keys(this.days)[idx]
      const maxHoursInDays = {}

      Array.from(currentMxHrs.children).map(div => parseInt(div.children[1].children[0].value)).forEach((hour, idx) => {
        if (hour) {
          maxHoursInDays[getDay(idx)] = hour
        } else {
          maxHoursInDays[getDay(idx)] = 0
        }
      })
      return maxHoursInDays
    },

    checkIfAnyMaxHourFieldExceedsActualMaxHourByServiceHours () {
      const currentMxHrs = document.getElementById('max_hrs_input_fields')
      Array.from(currentMxHrs.children).forEach((div, idx) => {
        const valueInField = parseInt(div.children[1].children[0].value)
        if (valueInField > this.form.max_hrs[idx]) {
          div.children[1].children[0].value = this.form.max_hrs[idx]
        }
      })
    },

    async saveChanges () {
      this.v$.form.$touch()
      const formValid = !(this.v$.form.$error)

      if (!formValid) {
        return
      }

      // this.isLoadingSave = true

      this.setLoading(true)

      const data = new FormData()
      if (this.isNewPic) {
        data.append('image', this.form.image)
      }

      data.append('name', this.nameOfArea)
      data.append('description', this.descriptionOfArea)
      data.append('serviceHours', JSON.stringify(this.getHoursBlocks(this.form.serviceHours)))
      data.append('maxHoursReservation', JSON.stringify(this.formatMaxHoursToDays()))
      data.append('maxPeople', `${this.maxPeopleOfArea}`)
      data.append('type', `${this.form.type.id}`)
      data.append('residential', `${this.residentialId}`)
      data.append('rulesOfUse', `${this.rulesOfUseOfArea}`)
      data.append('lockReservationsUntilDays', this.form.lockReservationsUntilDays || 0)

      // if (data) {
      //   for (const [key, val] of data.entries()) {
      //     console.log(`key: ${key} - value: ${val}`)
      //   }
      //   this.setLoading(false)
      //   this.$swal(this.$t('areas.updated'), '', 'success')
      //   return
      // }

      updateSocialArea(this.id, data).then((r) => {
        // console.log('updated area ----->', r.data)
        this.update_area({
          ...r.data,
          socialAreaType: {
            id: this.form.type.id,
            name: this.form.type.description,
            icon: this.getIconByID(this.form.type.id),
            status: 'INACTIVE'
          }
        })
        // this.isLoadingSave = false
        this.close()
        this.$swal(this.$t('areas.updated'), '', 'success')
      }).catch(() => {
        // this.isLoadingSave = false
        this.$swal(this.$t('errors.areas.update'), '', 'error')
      }).finally(() => {
        this.setLoading(false)
      })
    },

    ...mapActions('socialAreas', [
      'addNewArea',
      'delete_Area',
      'update_area'
    ]),

    async createNewArea () {
      this.v$.form.$touch()
      const formValid = !(this.v$.form.$error)

      if (!formValid) {
        return
      }

      // this.isLoadingSave = true
      this.setLoading(true)

      const data = new FormData()
      data.append('image', this.form.image)
      data.append('name', this.nameOfArea)
      data.append('description', this.descriptionOfArea)
      data.append('serviceHours', JSON.stringify(this.getHoursBlocks(this.form.serviceHours)))
      data.append('maxHoursReservation', JSON.stringify(this.formatMaxHoursToDays()))
      data.append('maxPeople', `${this.maxPeopleOfArea}`)
      data.append('type', `${this.form.type.id}`)
      data.append('residential', `${this.residentialId}`)
      data.append('rulesOfUse', `${this.rulesOfUseOfArea}`)
      data.append('lockReservationsUntilDays', this.form.lockReservationsUntilDays || 0)

      // ------------------------------------------------------------------------------ QUIT COMENT AFTER TESTS
      // console.log(data.getAll())
      // for (const pair of data.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`)
      // }

      createSocialArea(data).then((r) => {
        this.addNewArea({
          ...r.data,
          socialAreaType: {
            id: this.form.type.id,
            name: this.form.type.description,
            icon: this.getIconByID(this.form.type.id),
            status: 'ACTIVE'
          }
        })
        // this.isLoadingSave = false
        this.close()
        this.$swal(this.$t('areas.created'), '', 'success')
      }).catch((error) => {
        // this.isLoadingSave = false
        this.$swal(this.$t('errors.areas.creation'), '', 'error')
        console.log('error creating social area --> ', error)
      }).finally(() => {
        this.setLoading(false)
      })
    },

    async deleteArea () {
      // this.isLoadingSave = false
      // console.log('ID of space deleted ', this.edit_data.id)
      this.$swal({
        title: this.$t('areas.sureToDeleteArea'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        confirmButtonColor: '#dc3545',
        cancelButtonText: this.$t('no'),
        cancelButtonColor: '#2186b0'
      }).then(async (result) => {
        if (result.isConfirmed) {
          // this.isDeleting = true
          this.setLoading(true)
          deleteSocialArea(this.edit_data.id).then((r) => {
            this.delete_Area(r.data)
            this.close()
            this.$swal(this.$t('areas.deleted'), '', 'success')
          }).finally(() => {
            this.setLoading(false)
          })
        }
      })
    }
  },
  watch: {
    'form.serviceHours': {
      handler () {
        this.setMaxHoursPerDay()
        // console.log(this.form.serviceHours)
        // console.log(this.form.max_hrs)

        const SomeInvalidBlockExist = Object.values(this.getHoursBlocks(this.form.serviceHours)).some(blocks => blocks.some(block => block.length === 4))
        const ServiceHours = this.form.serviceHours
        const hoursBlocks = this.getHoursBlocks(ServiceHours)
        const BlocksValues = Object.values(hoursBlocks)

        if (!SomeInvalidBlockExist) {
          this.daysWithInvalidBlockHours = new Array(7).fill(0)
        } else {
          BlocksValues.forEach((blocks, idx) => {
            const dayInLetters = Object.keys(hoursBlocks)[idx]
            const dayInIdx = Object.keys(this.days).indexOf(dayInLetters)
            this.daysWithInvalidBlockHours[dayInIdx] = blocks.some(block => block.length === 4) ? 1 : 0
          })
        }

        this.checkIfAnyMaxHourFieldExceedsActualMaxHourByServiceHours()

        // UPDATING MIN VALUE TO 1 IF THERE ARE SERVICE HOURS IN A CERTAIN DAY
        if (document.getElementsByClassName('middle')) {
          for (let day = 0; day < 7; day++) {
            const maxHourDayField = document.getElementById(`max_hrs_${day}`)
            // console.log('day ', day, ' and element ', maxHourDayField)
            // console.log('max hr for day ', day, ' is ', this.form.max_hrs[day])
            if (this.form.max_hrs[day] > 0 && maxHourDayField.min === '0') {
              // document.getElementById(`max_hrs_${day}`).value = '1'
              // console.log(maxHourDayField.value, ' for day ', day)
              maxHourDayField.value = '1'
              // console.log('day ', day, ' has 0 min hours, but should be 1')
            }
          }
        }

        // Object.values(ServiceHours).forEach((arrayOf24Hours, idx) => {
        //   if (arrayOf24Hours.every(el => el === 0)) {
        //     this.form.max_hrs[idx] = 0
        //   }
        // })
      },
      deep: true
    },
    'form.max_hrs': {
      handler () {
        for (let idx = 0; idx < this.maxHoursOfArea.length; idx++) {
          if (this.maxHoursOfArea[idx] > this.currentMaxHoursOfArea[idx] || this.maxHoursOfArea[idx] < this.currentMaxHoursOfArea[idx]) {
            this.currentMaxHoursOfArea[idx] = this.maxHoursOfArea[idx]
          }
        }

        // if (document.getElementsByClassName('middle')) {
        //   for (let day = 0; day < 7; day++) {
        //     const maxHourDayField = document.getElementById(`max_hrs_${day}`)
        //     if (maxHourDayField && this.maxHoursOfArea[day] && this.form.max_hrs[day]) {
        //       if (maxHourDayField.value === 0) {
        //         console.log('value should vary if it is zero')
        //       }
        //       // maxHourDayField.value = (maxHourDayField.value === 0) ? 1 : maxHourDayField.value
        //       maxHourDayField.min = 1
        //     }
        //   }
        // }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
    h1 {
      color: #2c3e50 !important;
      font-family: inherit !important;
      margin: 0;
      padding: 0;
      padding-left: 50px !important;
      max-width: inherit;
    }

    /* .edit-area-container {
        background-color: #fff;
        border-radius: 20px;
        width: 100%;
        height: 600px;
    } */

    form {
        width: inherit;
    }

    .div-form {
        padding: 50px !important;
        padding-top: 0 !important;
        margin-top: 0 !important;
        display: flex !important;
        flex-direction: column !important;
        width: 100% !important;
        height: 100%;
    }

    label {
        color: #323232;
        /*color: #808080;*/
        font-family: inherit;
        font-size: 15px;
    }

    .name_field, .max_people_field, .type_field, .area_rules {
        display: flex;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .max_people_field {
      align-items: center;
    }
    .max_hrs_field, .area_rules_field {
      align-items: center;
    }

   /* .name_field {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between !important;
    } */

    /* .img_field {
        position: relative;
        width: inherit;
        height: inherit;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
        flex-direction: column;
    } */

    .txtAreaRules, .descriptionTxtArea {
      resize: none !important;
    }

    .txtAreaRulesFull {
      position: absolute;
      resize: none !important;
      /* z-index: 1000 !important; */
      height: 200px;
      width: '100%' !important;
      /* background-color: red !important; */
    }

    .descriptionTxtArea {
      margin-top:17px !important;
      /* border: 1px solid #2b7aaf !important;*/
    }

    /* .img_container {
      width: 100%;
      max-height: 690px;
      padding: 30px;
      padding-top: 15px;
      padding-left: 15px;
      padding-bottom: 30px;
    } */

    /* .img {
      position: inherit;
      width: inherit;
      max-height: 200px;
      object-fit: cover;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    } */

    /* .image__description {
      font-size: 18px;
    } */

    .btns_delete_save {
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: red; */
        /* margin-top: 20px; */
    }

  .delete, .save {
    height: 25px !important;
    text-transform: none !important;
    letter-spacing: normal !important;
    border-radius: 5px !important;
    transition: background-color .5s ease !important;
    border: none !important;
    width: 10rem !important;
    /*font-weight: bold;*/
    font-family: inherit;
  }

  .delete {
    margin-right: 20px !important;
  }
  /* .delete:hover{
   background-color: #5eb9dd !important;
  } */

  .save {
    margin-left: 20px !important;
  }
  /* .save:hover{
    background-color: #5b85ad !important;
  } */

  /* testing */
/*.image{
    position: relative;
    width: auto;
    max-width: 340px;
    height: 188px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}*/

/*.image__img{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
}*/

/*.image__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(10, 157, 216, 0.6);
    font-family: inherit;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.25s;
    cursor: pointer !important;
}*/

/*.image__overlay > * {
    transform: translateY(20px);
    transition: transform 0.25s
}*/

/* .image__overlay:hover {
    opacity: 1;
    cursor: pointer !important;
} */

/* .image__overlay:hover > * {
    transform: translateY(0);
} */

/* .upload_area {
  border: 2px dashed #ccc;
  height: 84%;
  width: 90%;
  display: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
} */
.image_field {
  display: flex;
  width:100%;
  height: 32%;
  color: black;
  align-items: center
}

.inputfile {
  height: 0.1px !important;
  width: 0.1px !important;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 5;
  cursor: pointer !important;
  padding: 0;
  margin: 0;
}
input[type=file]::-webkit-file-upload-button{
  cursor: pointer;
}

.inputfile + label {
    display: inline-block;
    cursor: pointer;
    color: black;
    font-weight: bold;
    font-size: 14px !important;
}

.bottom {
  width: 100%;
}

.bottom-left, .bottom-right {
  width: 50%;
}

.left, .right {
  width: 50% !important;
  height: 100% !important;
}

.left {
  padding-right: 10px;
}

.right {
  margin-left: 10px;
  padding-left: 15px;
}

.upper-right, .lower-right {
  height: 50%;
}
.lower-left {
  padding-top: 10px;
  height: 60%;
}
.upper-left {
  height: 40%;
  padding-top: 15px;
}

/* deleting levels */
  .level-container {
    display: flex !important;
    width: 100% !important;
    height: 50px;
    /*margin-top: 0.5rem !important;*/
  }
  .level-container .level {
    height: 5px !important;
    transition: all 0.5s !important;
  }
  .level-container .level-1 {
    background-color: #ff1a1a !important;
    width: 3.3rem !important;
  }
  .level-container .level-2 {
    background-color: yellow !important;
    width: 10rem !important;
  }
  .level-container .level-3 {
    background-color: limegreen !important;
    width: 16.6rem !important;
  }
  .level-container .level-4 {
    background-color: cadetblue !important;
    width: 20rem !important;
  }

  .bar {
    height: 25px !important;
    border-radius: 110px !important;
  }
/* Transitions */
.switch-enter-from,
.switch-leave-to {
  opacity: 0;
  /*overflow: hidden;
  transform: translateY(-5px);*/
}

.switch-enter-active, .switch-leave-active {
  /*overflow: hidden;
  transition: all 0.5s ease*/
  transition: opacity 1s ease
}

.disabled {
  pointer-events: none;
  background: rgba(255,255,255,.8);
  opacity: 0.1;
  cursor: none;
}

.disabledMaxInputHour {
  pointer-events: none;
  background: rgba(226, 72, 72, 0.8) !important;
  opacity: 0.1;
  cursor: none;
}

.upper-left-label {
  font-size: 16px;
}

/* --------------------------------- NEW --------------------------------------------------- */
.top-left {
  width: 35%;
}
.top-right {
  width: 65%;
  height: 33.5%;
  padding-left: 30px;
  padding-right: 9px;
  padding-top: 10px;
  margin-top:-11px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* Toooltip */
[data-position="right center"][data-tooltip]:before {
  background-color: #D3F7F5 !important;
  border: 0px;
  box-shadow: -1px 1px 0 0 #d3f7f5;
  margin-left: -0.2rem;
}

[data-position="top center"][data-tooltip]:before {
  background-color: #D3F7F5 !important;
  border: 0px;
  box-shadow: -1px 1px 0 0 #d3f7f5;
  margin-left: -0.2rem;
}

.maxHourDay {
  display: flex;
  margin-right: 0px !important;
  border: 1px solid #2b7aaf !important;
  border-radius: 4px 0px 0px 4px;
  margin-left: 7px;
  width: 30% !important;
  font-family: inherit;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.upload_btn_cover {
  background-color: #CACBCE;
  transition: background-color .5s ease !important;
}

.upload_btn_cover:hover {
 background-color: #9ea1ac !important;
}

.inputMaxHours {
  border: 1px solid rgb(96, 134, 160) !important;
  border-left: none !important;
  border-radius: 0px 4px 4px 0px !important;
  width: 40px !important;
  padding-right: 1px !important
}

.invalidHours {
  /* background-color: red !important; */
  background-color: #dd250d !important;
  border: 1px solid #af2b2b !important;
}

.invalidHoursInput {
  border: 1px solid #af2b2b !important;
  border-left: none !important;
}

textarea:focus {
  border-color: #85b7d9 !important;
}

.area_rules_container {
  min-height: 41.98px;
  max-height: 41.98px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>
