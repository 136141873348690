<template>
  <div class="justify-content-start view-container">
    <HeaderResidential />
  </div>
  <div class="justify-content-start w-100 view-container">
    <div class="top">
        <div class="ui icon input" :class="[{'disabledSection':(loading||noAreas)}]">
          <i class="search icon"></i>
          <input
            type="text"
            maxlength="25"
            v-model="searchInput"
            :placeholder="$t('areas.searchBarPlaceholder')"
            style="border-radius:4px !important"
          >
        </div>
    </div>
      <div class="social_areas">
          <div v-if="filteredAreas.length=== 0 && !searchInput && !loading && !noAreas" style="display: flex; flex-flow:wrap; margin-left: -10px">
            <div
            v-for="i in areas.length+1"
            :key="i">
              <AreaThumbnail
                v-if="i !== areas.length+1"
                :data="areas[i-1]"
                @click="openModal(areas[i-1])"
              />
              <AreaThumbnail
                v-if="i === areas.length+1 && residential.masterId === 0"
                :data="{}"
                @click="openModal({})"
              />
            </div>
          </div>
          <div v-else-if="filteredAreas.length> 0 && searchInput && !loading && !noAreas"  style="display: flex; flex-flow:wrap; margin-left: -10px">
            <div
            v-for="i in filteredAreas.length+1"
            :key="i">
              <AreaThumbnail v-if="i !== filteredAreas.length+1"
                :data="filteredAreas[i-1]"
                @click="openModal(filteredAreas[i-1])"
              />
              <AreaThumbnail v-else
                :data="{}"
                @click="openModal({name: searchInput})"
              />
            </div>
          </div>
          <div
            class="areaNotFound"
            v-else-if="noAreas && !noAreaFoundSearch && !loading"
          >
            <h2>{{$t('areas.noneCreated')}}</h2>
            <AreaThumbnail
              v-if="residential.masterId === 0"
              style="margin-left: -10px"
              :data="{}"
              @click="openModal({})"
            />
          </div>
        <AreaModal
            :show="isEditingModal"
            :edit_data="dataOfModal"
            :areas_types="formatedAreasTypes"
            :residentialId="IDofResidential"
            @close="closeModal"
          />
          <!-- :disableEdition="residential.masterId !== 0" -->

        <div class="areaNotFound" v-if="noAreaFoundSearch">
          <h2>{{$t('areas.notFound')}} '<strong style="color:#436f98">{{searchInput}}</strong>'</h2>
          <div style="display: flex">
            <h2
              v-if="residential.masterId === 0"
              @click="openModal({name: searchInput})"
              style="margin-top:0; cursor: pointer; border-bottom: 3px dotted;">
              {{$t('areas.createAreaSuggestion')}}
              <strong style="color:#436f98">{{searchInput}}</strong>
            </h2>
          </div>
        </div>
      </div>
    <template>
    </template>

  </div>
</template>

<script>
import AreaThumbnail from './AreaThumbnail.vue'
import HeaderResidential from '@/components/ui/HeaderResidential'
import AreaModal from '@/components/modals/AreaModal.vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    AreaThumbnail,
    HeaderResidential,
    AreaModal
  },
  data () {
    return {
      IDofResidential: 0,
      typesOfAreas: [],
      isEditing: false,
      isError: false,
      modalData: {},
      searchInput: '',
      filteredAreas: [],
      loading: false,
      isCreatingNewArea: false,
      isFillingForms: false,
      reservationTypes: [{ id: 'HOUS', description: 'HOUS' }, { id: 'DAYS', description: 'DAYS' }],
      form: {
        newAreaName: ''
      }
    }
  },
  // updated () {
  //   this.loadAreasTypes()
  //   this.loadAreas()
  //   this.IDofResidential = this.residential.residentialId
  // },
  mounted () {
    this.loadAreasTypes()
    this.loadAreas()
    this.IDofResidential = this.residential.residentialId
  },
  watch: {
    searchInput: function () {
      if (this.searchInput !== '') {
        const input = this.searchInput.trim().toLowerCase()
        this.filteredAreas = this.areasFiltered(input)
      } else {
        this.filteredAreas = []
      }
    }
  },
  computed: {
    ...mapState('types', {
      types: state => state.types
    }),

    ...mapGetters('types', [
      'formatedAreasTypes'
    ]),

    ...mapState('socialAreas', {
      areas: state => state.areas
    }),

    ...mapGetters('socialAreas', [
      'allAreas',
      'areasFiltered'
    ]),

    residential () {
      return this.$store.getters['residential/activeResidential']
    },
    nameOfNewArea (num) {
      const partialName = this.form.newAreaName.toString()
      return `${partialName + num}`
    },
    isEditingModal () {
      return this.isEditing
    },
    dataOfModal () {
      return {
        ...this.modalData,
        isLimitDaysRuleActive: this.modalData.lockReservationsUntilDays > 0
      }
    },
    noAreas () {
      return this.areas.length === 0
    },
    noAreaFoundSearch () {
      return (this.filteredAreas.length === 0 && this.searchInput !== '' && this.areas.length !== 0) || (this.filteredAreas.length === 0 && this.searchInput !== '' && this.areas.length === 0)
    }
  },
  methods: {

    ...mapActions('loading', ['setLoading']),

    ...mapActions('types', [
      'loadTypes'
    ]),

    ...mapActions('socialAreas', [
      'load_Areas'
    ]),

    loadAreas () {
      this.loading = true
      this.setLoading(true)
      // const residentialIdFetch = this.residential.isMaster
      //   ? this.residential.residentialId
      //   : this.residential.masterId
      const residentialIdFetch = this.residential.residentialId
      this.load_Areas(residentialIdFetch)
        .then(() => {
          this.loading = false
          this.setLoading(false)
        })
        .catch(() => {
          this.isError = false
          this.loading = false
          this.setLoading(false)
        })
    },

    loadAreasTypes () {
      this.loadTypes()
    },

    openModal (area) {
      this.isEditing = true
      this.modalData = area
    },

    closeModal () {
      this.isEditing = false
      this.modalData = {}
      if (this.searchInput) {
        const input = this.searchInput.trim().toLowerCase()
        if (this.filteredAreas !== this.areasFiltered(input)) {
          this.filteredAreas = this.areasFiltered(input)
        }
      }
    }
  }
}
</script>

<style scoped>

.view-container {
    text-align: left;
    margin-left: 30px;
    margin-top: 17px;
  }

.social_areas{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 0;
  margin-top: 5px;
  margin-bottom: 50px;
}

.top{
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.areaNotFound, .noAreas {
  margin-top: 15px;
  height: 188px !important;
}

button {
  color: white;
  background-color: #4472C4 !important;
}

.search_and_btn {
  display: flex;
}

.loaderAreas {
  /*width: 100vw;*/
  height: 300px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Switch transitions */
.switch-enter-from,
.switch-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.switch-enter-active, .switch-leave-active {
  transition: all 0.5s ease
}

.areasCreationContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.stepTitle {
  align-self: flex-start !important;
  margin-left: 30px !important;
}

.areasCreationContainerTitle{
  margin-top: 10px;
  border-bottom: 1px solid #2c3e50;
  margin-bottom: 25px;
  padding-left:15px;
  padding-right:15px
}

.step1 {
  display: flex;
  width: 100%;
  margin-left: 40px;
  margin-top: 25px;
  align-items: center;
  transition: all .2s ease-out;
}

.step2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  height: 100%;
  margin-bottom: 50px;
}

.formsContainer {
  display: flex;
  flex-direction: column;
}

.titleOfFieldStep1 {
  margin-right: 10px;
}

.disabledSection {
    pointer-events: none;
    opacity: 0.4;
}

.nameArea {
    border: none !important;
    background-color: #f9f9f9;
    border-bottom: 1px solid #8f989b !important;
    border-radius: 0 !important;
    width: inherit;
    padding-left: 0 !important;
    padding-right: 0 !important;
    transition: border-bottom .5s ease !important;
    outline: none !important;
    font-size: 1.2rem !important;
}

::placeholder{
    color: gray !important
  }

  .formAreaContainer {
    width: 700px;
    height: 450px;
    display: flex;
    flex-direction: column;
    align-self: center;
    /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;*/
    margin-top: 10px;
  }
</style>
